import React from 'react';

const OutputText = ({ text }) => {
  // Split the text into lines
  const lines = text.split('\n');

  // Function to process the text for bolding
  const processLine = (line) => {
    const regex = /\*\*(.*?)\*\*/g;
    const parts = line.split(regex);
    
    return parts.map((part, index) => (
      // If the part matches the regex (i.e., it's surrounded by **), make it bold and black
      index % 2 === 1 ? (
        <span key={index} className="font-bold text-black">
          {part}
        </span>
      ) : (
        part
      )
    ));
  };

  return (
    <div 
      className="pt-2 pl-2 pb-2 relative overflow-x-auto w-full"
      style={{
        whiteSpace: 'pre', // Preserve spaces and line breaks
        overflow: 'auto',
      }}
    >
      <div className="min-w-full">
        {lines.map((line, index) => (
          <div
            key={index}
            className={`text-gray-800 text-justify text-[12px] md:text-[14px] ${index % 2 === 0 ? 'white' : ''}`}
          >
            {processLine(line)}
          </div>
        ))}
      </div>

      <style jsx>{`
        /* For WebKit browsers */
        ::-webkit-scrollbar {
          height: 12px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </div>
  );
};

export default OutputText;

import React from 'react'
import { Helmet } from 'react-helmet';

import CodeBlock from '../../components/CodeBlock'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import SubTitle from '../../components/SubTitle'
import ImageBlock from '../../components/ImageBlock'
import DownSpace from '../../components/DownSpace'
import ColabButton from '../../components/ColabButton'
import Banner from '../../components/Banner'
import OutputText from '../../components/OutputText';
import EquationRenderer from '../../components/EquationRenderer';

const output_example = `**<class 'pandas.core.frame.DataFrame'>**
**RangeIndex: 135493 entries, 0 to 135492** 
**Data columns (total 6 columns):** 
**#    Column         Non-Null Count   Dtype**  
**---  ------         --------------   -----**  
 **0**   disrict        135493 non-null  **int64**  
 **1**   client_id      135493 non-null  **object** 
 **2**   client_catg    135493 non-null  **int64**  
 **3**   region         135493 non-null  **int64**  
 **4**   creation_date  135493 non-null  **object** 
 **5**   target         135493 non-null  **float64** 
**dtypes: float64(1), int64(3), object(2)** 
**memory usage: 6.2+ MB** `

const example_2 = `**<class 'pandas.core.frame.DataFrame'>**
**RangeIndex: 4476749 entries, 0 to 4476748**
**Data columns (total 16 columns):**
** #   Column                Dtype** 
**---  ------                -----** 
 **0**   client_id             **object**
 **1**   invoice_date          **object**
 **2**   tarif_type            **int64** 
 **3**   counter_number        **int64** 
 **4**   counter_statue        **object**
 **5**   counter_code          **int64** 
 **6**   reading_remarque      **int64** 
 **7**   counter_coefficient   **int64** 
 **8**   consommation_level_1  **int64** 
 **9**   consommation_level_2  **int64** 
 **10**  consommation_level_3  **int64** 
 **11**  consommation_level_4  **int64** 
 **12**  old_index             **int64** 
 **13**  new_index             **int64** 
 **14**  months_number         **int64** 
 **15**  counter_type          **object**
**dtypes: int64(12), object(4)**
**memory usage: 546.5+ MB**`

const another_example =`**        disrict	      client_id	                client_catg	        region	        creation_date	      target**
**0**       60	          train_Client_0	        11	                101	            31/12/1994	          0.0
**1**	    69	          train_Client_1	        11	                107	            29/05/2002	          0.0
**2**	    62	          train_Client_10	        11	                301	            13/03/1986	          0.0
**3**	    69	          train_Client_100	        11	                105	            11/07/1996	          0.0
**4**	    62	          train_Client_1000	        11	                303	            14/10/2014	          0.0`

const another_example_2 = `
**        client_id	       invoice_date	      tarif_type	    counter_number	    counter_statue	      counter_code	      reading_remarque	    counter_coefficient	      consommation_level_1	      consommation_level_2	    consommation_level_3	    consommation_level_4	    old_index	    new_index	    months_number	    counter_type **  
**0**	    train_Client_0	   2014-03-24	      11	            1335667	            0	                  203	              8	                    1	                      82	                      0	                        0	                        0	                        14302	        14384	        4	                ELEC
**1**	    train_Client_0	   2013-03-29	      11	            1335667	            0	                  203	              6	                    1	                      1200	                      184	                    0	                        0	                        12294	        13678	        4	                ELEC
**2**	    train_Client_0	   2015-03-23	      11	            1335667	            0	                  203	              8	                    1	                      123	                      0	                        0	                        0	                        14624	        14747	        4	                ELEC
**3**	    train_Client_0	   2015-07-13	      11	            1335667	            0	                  207	              8	                    1	                      102	                      0	                        0	                        0	                        14747	        14849	        4	                ELEC
**4**	    train_Client_0	   2016-11-17	      11	            1335667	            0	                  207	              9	                    1	                      572	                      0	                        0	                        0	                        15066	        15638	        12	                ELEC`

const describe_1 = `
          **disrict	        client_catg	      region	        target**
**count**	  135493	        135493	          135493	        135493
**mean**	  63.51	            11.51	          206.15	        0.0558
**std**	      3.35	            4.42	          104.20	        0.22
**min**	      60.00	            11.00	          101.00	        0.00
**25%**	      62.00	            11.00	          103.00	        0.00
**50%**	      62.00	            11.00	          107.00	        0.00
**75%**	      69.00	            11.00	          307.00	        0.00
**max**	      69.00	            51.00	          399.00	        1.00`

const array = `**array([**101, 107, 301, 105, 303, 103, 309, 311, 304, 104, 312, 305, 306,
308, 372, 307, 313, 310, 371, 302, 106, 379, 399, 206, 199**])**`

const date_table = `
          **creation_date**
**0**	      31/12/1994
**1**	      29/05/2002
**2**	      13/03/1986
**3**	      11/07/1996
**4**	      14/10/2014
**...	...**
**135488**	  26/07/2004
**135489**	  25/10/2012
**135490**	  22/11/2011
**135491**	  22/12/1993
**135492**	  18/02/1986
**135493 rows × 1 columns**


**dtype: object**`

const sorted_array = `
        **creation_date**
**count**	135493
**mean**	2002-10-01 18:45:16.001564544
**min**	    1977-02-05 00:00:00
**25%**	    1994-01-12 00:00:00
**50%**	    2005-09-19 00:00:00
**75%**	    2012-04-04 00:00:00
**max**	    2019-09-10 00:00:00
**dtype: object**`

const import_csv = `df_client  = pandas.read_csv('./fraud-detection-in-electricity-and-gas-consumption/client_train.csv',  low_memory=False)
df_invoice = pandas.read_csv('./fraud-detection-in-electricity-and-gas-consumption/invoice_train.csv', low_memory=False)`

const plot_values = `def plot_transactions_and_fraud(df, date_column='creation_date', target='target'):
    # Convert the date column to datetime
    df[date_column] = pandas.to_datetime(df[date_column])

    # Extract the year from the creation date
    df['year'] = df[date_column].dt.year

    # Group by year and count total transactions and fraud cases
    transactions_per_year = df.groupby('year').size()  # Total transactions
    fraud_cases_per_year = df[df[target] == 1].groupby('year').size()  # Fraud transactions

    matplotlib.pyplot.figure(figsize=(10, 6))

    # Plot total transactions
    matplotlib.pyplot.plot(transactions_per_year.index, transactions_per_year.values, label='Total Transactions', marker='o')

    # Plot fraud cases
    matplotlib.pyplot.plot(fraud_cases_per_year.index, fraud_cases_per_year.values, label='Fraud Cases', marker='o', color='r')

    matplotlib.pyplot.title('Transactions and Fraud Cases per Year')
    matplotlib.pyplot.xlabel('Year')
    matplotlib.pyplot.ylabel('Count')

    matplotlib.pyplot.legend()

    matplotlib.pyplot.show()`

const balance = `def fraud_percentage(feature, df=df_client, target='target'):
    # Count the total number of reports (both fraud and non-fraud) per category in the feature
    total_count = df[feature].value_counts()

    # Count the number of fraud reports (where target == 1) per category
    fraud_count = df[df[target] == 1][feature].value_counts()

    # Align fraud_count with total_count to ensure they have the same categories
    fraud_count = fraud_count.reindex(total_count.index, fill_value=0)

    # Calculate the fraud percentage for each category (fraud cases / total cases in each district)
    fraud_percentage = (fraud_count / total_count) * 100

    # Calculate the percentage contribution to the total fraud cases (so it sums to 100%)
    fraud_distribution = (fraud_count / fraud_count.sum()) * 100

    # Combine the results into a new DataFrame
    result_df = pandas.DataFrame({
        feature: total_count.index,
        'Fraud Reports': fraud_count.astype(int),
        'Total Reports': total_count.values,
        'Fraud Percentage (Within Category)': fraud_percentage.values,
        'Total Fraud Contribution (%)': fraud_distribution.values
    }).reset_index(drop=True)

    return result_df`

const f1 = `	    **disrict	    Fraud Reports	    Total Reports	    Fraud Percentage (Within Category)	    Total Fraud Contribution (%)**
**0**	    62	        2083	            40353	            5.161946	                            27.531060
**1**	    69	        2447	            34231	            7.148491	                            32.342057
**2**	    60	        1145	            31922	            3.586868	                            15.133492
**3**	    63	        1891	            28987	            6.523614	                            24.993391`

const f2 = `        **region	    Fraud Reports	    Total Reports	    Fraud Percentage (Within Category)	    Total Fraud Contribution (%)**
**0**	    101	        1213                33770	            3.591946	                            16.032250
**1**	    104	        720	                12865	            5.596580	                            9.516257
**2**	    311	        998	                12406	            8.044495	                            13.190589
**3**	    107	        658	                9998	            6.581316	                            8.696801
**4**	    301	        315	                9544	            3.300503	                            4.163362
**5**	    103	        923	                8964	            10.296743	                            12.199313
**6**	    306	        294	                6044	            4.864328	                            3.885805
**7**	    303	        326	                5982	            5.449682	                            4.308750
**8**	    310	        247	                4851	            5.091734	                            3.264605
**9**	    312	        218	                4379	            4.978306	                            2.881311
**10**	    302	        266	                4311	            6.170262	                            3.515728
**11**	    304	        256	                3828	            6.687565	                            3.383558
**12**	    309	        158	                3669	            4.306351	                            2.088290
**13**	    307	        210	                3376	            6.220379	                            2.775575
**14**	    305	        157	                2716	            5.780560	                            2.075073
**15**	    313	        147	                2076	            7.080925	                            1.942902
**16**	    371	        148	                2065	            7.167070	                            1.956119
**17**	    105	        92	                1645	            5.592705	                            1.215966
**18**	    308	        61	                841	                7.253270	                            0.806238
**19**	    106	        53	                706	                7.507082	                            0.700502
**20**	    372	        65	                644	                10.093168	                            0.859107
**21**	    379	        39	                583	                6.689537	                            0.515464
**22**	    399	        1	                175	                0.571429	                            0.013217
**23**	    206	        1	                53	                1.886792	                            0.013217
**24**	    199	        0	                2	                0.000000	                            0.000000`

const f3 = `        **client_catg	        Fraud Reports	    Total Reports	     Fraud Percentage (Within Category)	        Total Fraud Contribution (%)**
**0**	    11	                7191	            131494	             5.468691	                                95.043616
**1**	    12	                92	                2321	             3.963809	                                1.215966
**2**	    51	                283	                1678	             16.865316	                                3.740418`

const cram = `def cramers_v(x, y):
    """
    Calculate Cramér's V statistic for categorical-categorical association.
    :param x: Categorical variable 1
    :param y: Categorical variable 2
    :return: Cramér's V statistic
    """
    confusion_matrix = pandas.crosstab(x, y)
    chi2 = stats.chi2_contingency(confusion_matrix)[0]  # chi-squared statistic
    n = confusion_matrix.sum().sum()  # total number of samples
    phi2 = chi2 / n
    r, k = confusion_matrix.shape
    # Adjust phi2 to account for bias
    phi2corr = max(0, phi2 - ((k - 1) * (r - 1)) / (n - 1))
    rcorr = r - ((r - 1) ** 2) / (n - 1)
    kcorr = k - ((k - 1) ** 2) / (n - 1)
    # Return Cramér's V statistic
    return numpy.sqrt(phi2corr / min((kcorr - 1), (rcorr - 1)))`

const bis = `def point_biserial_corr(x, y):
    """
    Calculate the point-biserial correlation coefficient between a binary and a continuous variable.
    :param x: Binary categorical variable (e.g., target variable)
    :param y: Continuous or categorical variable converted to numeric codes
    :return: Point-biserial correlation coefficient
    """
    correlation, _ = stats.pointbiserialr(x, y)
    return correlation`

const pearson = `def pearson_corr(x, y):
    """
    Calculate the Pearson correlation coefficient between two continuous variables.
    :param x: Continuous variable 1
    :param y: Continuous variable 2 (can be a categorical variable converted to numeric)
    :return: Pearson correlation coefficient
    """
    correlation, _ = stats.pearsonr(x, y)
    return correlation`

const corrs = `def create_correlation_table(df, categorical_columns, target_column, datetime_column, convert_datetime=False):

    all_columns = categorical_columns + [target_column, datetime_column]

    # Initialize an empty correlation table
    correlation_matrix = pandas.DataFrame(index=all_columns, columns=all_columns)

    # 1. Categorical vs Categorical (Cramér's V)
    for i in range(len(categorical_columns)):
        for j in range(i, len(categorical_columns)):
            col_i = categorical_columns[i]
            col_j = categorical_columns[j]
            correlation = cramers_v(df[col_i], df[col_j])
            correlation_matrix.loc[col_i, col_j] = correlation
            correlation_matrix.loc[col_j, col_i] = correlation

    # 2. Categorical vs Target (Cramér's V)
    for col in categorical_columns:
        correlation = cramers_v(df[col], df[target_column])
        correlation_matrix.loc[col, target_column] = correlation
        correlation_matrix.loc[target_column, col] = correlation

    # 3. Categorical vs Datetime (Pearson Correlation)
    if convert_datetime:
        df[datetime_column] = pandas.to_datetime(df[datetime_column], errors='coerce') 
    
    df[datetime_column] = df[datetime_column].apply(lambda x: x.timestamp() if pandas.notnull(x) else numpy.nan)
    for col in categorical_columns:
        df[col], _ = pandas.factorize(df[col].astype(str))
        correlation = pearson_corr(df[datetime_column].dropna(), df[col].dropna())
        correlation_matrix.loc[col, datetime_column] = correlation
        correlation_matrix.loc[datetime_column, col] = correlation

    # 4. Datetime vs Target (Point-Biserial Correlation)
    correlation = point_biserial_corr(df[datetime_column], df[target_column])
    correlation_matrix.loc[datetime_column, target_column] = correlation
    correlation_matrix.loc[target_column, datetime_column] = correlation

    # 5. Self-Correlations (Target vs Target and Datetime vs Datetime should be 1)
    correlation_matrix.loc[target_column, target_column] = 1.0
    correlation_matrix.loc[datetime_column, datetime_column] = 1.0

    for col in categorical_columns:
        correlation_matrix.loc[col, col] = 1.0

    return correlation_matrix`

const corrs_o = `                **disrict 	client_catg	    region	    target	    creation_date**
**disrict**	        **1.0**	        0.03622	        0.980419	0.058241	0.137422
**client_catg**	    0.03622	    **1.0**	            0.055906	0.055539	0.053898
**region**	        0.980419	0.055906	    **1.0**	        0.084384	-0.005332
**target**	        0.058241	0.055539	    0.084384	**1.0**	        -0.05224
**creation_date**	0.137422	0.053898	    -0.005332	-0.05224	**1.0**`

const output_test_2 = `**        client_id	       invoice_date	      tarif_type	    counter_number	    counter_statue	      counter_code	      reading_remarque	    counter_coefficient	      consommation_level_1	      consommation_level_2	    consommation_level_3	    consommation_level_4	    old_index	    new_index	    months_number	    counter_type       target **  
**0**	    train_Client_0	   2014-03-24	      11	            1335667	            0	                  203	              8	                    1	                      82	                      0	                        0	                        0	                        14302	        14384	        4	                ELEC               0.0
**1**	    train_Client_0	   2013-03-29	      11	            1335667	            0	                  203	              6	                    1	                      1200	                      184	                    0	                        0	                        12294	        13678	        4	                ELEC               0.0
**2**	    train_Client_0	   2015-03-23	      11	            1335667	            0	                  203	              8	                    1	                      123	                      0	                        0	                        0	                        14624	        14747	        4	                ELEC               0.0
**3**	    train_Client_0	   2015-07-13	      11	            1335667	            0	                  207	              8	                    1	                      102	                      0	                        0	                        0	                        14747	        14849	        4	                ELEC               0.0
**4**	    train_Client_0	   2016-11-17	      11	            1335667	            0	                  207	              9	                    1	                      572	                      0	                        0	                        0	                        15066	        15638	        12	                ELEC               0.0`

const uniques = `for column in df_invoice.columns:
    print(f'{column}: {len(df_invoice[column].unique())}')`

const uniques_o = `**client_id:** 135493
**invoice_date:** 8275
**tarif_type: **17
**counter_number: **201893
**counter_statue:** 12
**counter_code:** 42
**reading_remarque:** 8
**counter_coefficient:** 16
**consommation_level_1:** 8295
**consommation_level_2:** 12576
**consommation_level_3:** 2253
**consommation_level_4:** 12075
**old_index:** 155648
**new_index:** 157980
**months_number:** 1370
**counter_type:** 2
**target:** 2`

const b1 = `        **tarif_type	    Fraud Reports	    Total Reports	     Fraud Percentage (Within Category)	    Total Fraud Contribution (%)  **
**0**	    11	            197385	            2679872	             7.365464	                            55.898695
**1**	    40	            113422	            1379755	             8.220445	                            32.120687
**2**	    10	            24605	            276210	             8.908077	                            6.968044
**3**	    15	            8933	            72422	             12.334650	                            2.529792
**4**	    45	            3342	            17552	             19.040565	                            0.946442
**5**	    13	            1301	            11656	             11.161633	                            0.368438
**6**	    14	            1302	            11611	             11.213504	                            0.368722
**7**	    12	            1264	            11345	             11.141472	                            0.357960
**8**	    29	            1098	            10090	             10.882061	                            0.310950
**9**	    9	            444	                6039	             7.352211	                            0.125739
**10**	    21	            11	                104	                 10.576923	                            0.003115
**11**	    8	            1	                43	                 2.325581	                            0.000283
**12**	    30	            0	                35	                 0.000000	                            0.000000
**13**	    24	            0	                9	                 0.000000	                            0.000000
**14**	    18	            4	                4	                 100.000000	                            0.001133
**15**	    42	            0	                1	                 0.000000	                            0.000000
**16**	    27	            0	                1	                 0.000000	                            0.000000`

const b2 = `    **    counter_statue	      Fraud Reports	      Total Reports	      Fraud Percentage (Within Category)	  Total Fraud Contribution (%)  **
**0**	    0	                  345295	          4379008	          7.885233	                              97.786255
**1**	    1	                  5402	              74036	              7.296450	                              1.529826
**2**	    5	                  2159	              20639	              10.460778	                              0.611421
**3**	    4	                  235	              2729	              8.611213	                              0.066551
**4**	    3	                  19	              258	              7.364341	                              0.005381
**5**	    2	                  2	                  32	              6.250000	                              0.000566
**6**	    46	                  0	                  14	              0.000000	                              0.000000
**7**	    A	                  0	                  13	              0.000000	                              0.000000
**8**	    618	                  0	                  12	              0.000000	                              0.000000
**9**	    769	                  0	                  6	                  0.000000	                              0.000000
**10**	    269375	              0	                  1	                  0.000000	                              0.000000
**11**	    420	                  0	                  1	                  0.000000	                              0.000000`

const b3 = `        **  counter_code	        Fraud Reports	      Total Reports	      Fraud Percentage (Within Category)	      Total Fraud Contribution (%)    **
**0**	      203	                113672	              1516836	          7.494020	                                  32.191486
**1**	      5	                    109827	              1352035	          8.123089	                                  31.102596
**2**	      207	                32142	              555628	          5.784806	                                  9.102494
**3**	      413	                31405	              378917	          8.288095	                                  8.893779
**4**	      202	                33129	              343251	          9.651538	                                  9.382009
**5**	      420	                8597	              98273	              8.748079	                                  2.434638
**6**	      410	                4953	              69080	              7.169948	                                  1.402671
**7**	      433	                3944	              34447	              11.449473	                                  1.116926
**8**	      10	                3595	              27744	              12.957757	                                  1.018091
**...**
**31**	      310	                0	                  51	              0.000000	                                  0.000000
**32**	      307	                0	                  50	              0.000000	                                  0.000000
**33**	      305	                0	                  44	              0.000000	                                  0.000000
**34**	      222	                0	                  42	              0.000000	                                  0.000000
**35**	      0	                    0	                  33	              0.000000	                                  0.000000
**36**	      317	                0	                  16	              0.000000	                                  0.000000
**37**	      303	                0	                  5	                  0.000000	                                  0.000000
**38**	      333	                0	                  4	                  0.000000	                                  0.000000
**39**	      367	                0	                  3	                  0.000000	                                  0.000000
**40**	      325	                0	                  1	                  0.000000	                                  0.000000
**41**	      1	                    0	                  1	                  0.000000	                                  0.000000`

const b4 = `          **reading_remarque	      Fraud Reports	      Total Reports	      Fraud Percentage (Within Category)	      Total Fraud Contribution (%)**    
**0**	      6	                      171938	          2230939	          7.706979	                                  48.692200
**1**	      9	                      113404	          1416992	          8.003150	                                  32.115589
**2**	      8	                      67769	              828123	          8.183446	                                  19.191928
**3**	      7	                      1	                  661	              0.151286	                                  0.000283
**4**	      203	                  0	                  15	              0.000000	                                  0.000000
**5**	      413	                  0	                  12	              0.000000	                                  0.000000
**6**	      207	                  0	                  6	                  0.000000	                                  0.000000
**7**	      5	                      0	                  1	                  0.000000	                                  0.000000`

const b5 = `	   **   counter_coefficient	      Fraud Reports	      Total Reports	      Fraud Percentage (Within Category)	      Total Fraud Contribution (%)    **
**0**	      1	                          353109	          4475102	          7.890524	                                  99.999150
**1**	      2	                          0	                  886	              0.000000	                                  0.000000
**2**	      3	                          0	                  321	              0.000000	                                  0.000000
**3**	      40	                      0	                  197	              0.000000	                                  0.000000
**4**	      30	                      0	                  137	              0.000000	                                  0.000000
**5**	      0	                          1	                  46	              2.173913	                                  0.000283
**6**	      6	                          0	                  30	              0.000000	                                  0.000000
**7**	      4	                          0	                  12	              0.000000	                                  0.000000
**8**	      10	                      1	                  6	                  16.666667	                                  0.000283
**9**	      20	                      0	                  3	                  0.000000	                                  0.000000
**10**	      9	                          0	                  3	                  0.000000	                                  0.000000
**11**	      50	                      0	                  2	                  0.000000	                                  0.000000
**12**	      33	                      0	                  1	                  0.000000	                                  0.000000
**13**	      5	                          1	                  1	                  100.000000	                              0.000283
**14**	      11	                      0	                  1	                  0.000000	                                  0.000000
**15**	      8	                          0	                  1	                  0.000000	                                  0.000000`

const b6 = `	      **counter_type	      Fraud Reports	      Total Reports	      Fraud Percentage (Within Category)	      Total Fraud Contribution (%)**
**0**	      ELEC	              236348	          3079406	          7.675117	                                  66.932871
**1**	      GAZ	              116764	          1397343	          8.356144	                                  33.067129`

const describe_4 = ` **       tarif_type	    counter_number	counter_code	reading_remarque	counter_coefficient	    consommation_level_1	consommation_level_2	consommation_level_3	consommation_level_4	old_index	      new_index	        months_number	target        **
**count**	4.476749e+06	4.476749e+06	4.476749e+06	4.476749e+06	    4.476749e+06	        4.476749e+06	        4.476749e+06	        4.476749e+06	        4.476749e+06	        4.476749e+06	  4.476749e+06	    4.476749e+06	4.476749e+06
**mean**	2.012804e+01	1.230587e+11	1.724884e+02	7.321702e+00	    1.003040e+00	        4.109795e+02	        1.093225e+02	        2.030620e+01	        5.292588e+01	        1.776700e+04	  1.834970e+04	    4.483095e+01	7.887688e-02
**std**	    1.347256e+01	1.657267e+12	1.338871e+02	1.571654e+00	    3.083466e-01	        7.573080e+02	        1.220123e+03	        1.574239e+02	        8.754725e+02	        4.036693e+04	  4.095321e+04	    3.128335e+03	2.695465e-01
**min**	    8.000000e+00	0.000000e+00	0.000000e+00	5.000000e+00	    0.000000e+00	        0.000000e+00	        0.000000e+00	        0.000000e+00	        0.000000e+00	        0.000000e+00	  0.000000e+00	    0.000000e+00	0.000000e+00
**25%**	    1.100000e+01	1.211080e+05	5.000000e+00	6.000000e+00	    1.000000e+00	        7.900000e+01	        0.000000e+00	        0.000000e+00	        0.000000e+00	        1.791000e+03	  2.056000e+03	    4.000000e+00	0.000000e+00
**50%**	    1.100000e+01	4.945610e+05	2.030000e+02	8.000000e+00	    1.000000e+00	        2.740000e+02	        0.000000e+00	        0.000000e+00	        0.000000e+00	        7.690000e+03	  8.192000e+03	    4.000000e+00	0.000000e+00
**75%**	    4.000000e+01	1.115161e+06	2.070000e+02	9.000000e+00	    1.000000e+00	        6.000000e+02	        0.000000e+00	        0.000000e+00	        0.000000e+00	        2.166000e+04	  2.234300e+04	    4.000000e+00	0.000000e+00
**max**	    4.500000e+01	2.798115e+13	6.000000e+02	4.130000e+02	    5.000000e+01	        9.999100e+05	        9.990730e+05	        6.449200e+04	        5.479460e+05	        2.800280e+06	  2.870972e+06	    6.366240e+05	1.000000e+00`

const pp = `categorical_columns = ['tarif_type', 'counter_statue', 'counter_code',
                       'consommation_level_1', 'consommation_level_2', 'consommation_level_3', 'consommation_level_4',
                       'reading_remarque', 'counter_type']
                       
target_column = 'target'
datetime_column = 'invoice_date'

create_correlation_table(df_invoice, categorical_columns, target_column, datetime_column, True)`

const p = `categorical_columns = ['disrict', 'client_catg', 'region']
target_column = 'target'
datetime_column = 'creation_date'

create_correlation_table(df_client, categorical_columns, target_column, datetime_column)`

const ppp = `	
                  **      tarif_type	counter_statue	counter_code	consommation_level_1	consommation_level_2	consommation_level_3	consommation_level_4	reading_remarque	counter_type	target	    invoice_date    **
**tarif_type**	            **1.0**	        0.018822	    0.438653	    0.261699	            0.09818	                0.033386	            0.0	                    0.057495	        0.999998	    0.040289	-0.021277
**counter_statue**	        0.018822	**1.0**	            0.427366	    0.077832	            0.045237	            0.0	                    0.0	                    0.756646	        0.041559	    0.006993	-0.083249
**counter_code**	        0.438653	0.427366	    **1.0**	            0.269416	            0.201738	            0.085068	            0.187873	            0.539656	        0.999996	    0.055251	0.012882
**consommation_level_1**	0.261699	0.077832	    0.269416	    **1.0**	                    0.0	                    0.050584	            0.0	                    0.184307	        0.569629	    0.061181	-0.037062
**consommation_level_2**	0.09818	    0.045237	    0.201738	    0.0	                    **1.0**	                    0.050703	            0.0	                    0.117954	        0.274252	    0.060298	-0.094926
**consommation_level_3**	0.033386	0.0	            0.085068	    0.050584	            0.050703	            **1.0**	                    0.062038	            0.098903	        0.13739	        0.048986	0.104248
**consommation_level_4**	0.0	        0.0	            0.187873	    0.0	                    0.0	                    0.062038	            **1.0**	                    0.05285	            0.083217	    0.05946 	0.09138
**reading_remarque**	    0.057495	0.756646	    0.539656	    0.184307	            0.117954	            0.098903	            0.05285	                **1.0**	                0.086423	    0.007857	0.2122
**counter_type**	        0.999998	0.041559	    0.999996	    0.569629	            0.274252	            0.13739	                0.083217	            0.086423	        **1.0**	            0.011697	0.07696
**target**	                0.040289	0.006993	    0.055251	    0.061181	            0.060298	            0.048986	            0.05946	                0.007857	        0.011697	    **1.0**	        0.001534
**invoice_date**	        -0.021277	-0.083249	    0.012882	    -0.037062	            -0.094926	            0.104248	            0.09138	                0.2122	            0.07696	        0.001534	**1.0**`

const YOLO = () => {
  return (
    <div className='custom-font pt-9'>
        <Helmet>
          <title>Detecting Fraud in Electricity and Gas Consumption Using Decision Tree Models</title>
          <meta name="author" content="Dinis Martinho" />
          <meta name="description" content="" />
        </Helmet>


        <Title Title="Detecting Fraud in Electricity and Gas Consumption Using Decision Tree Models" date='31 Jan 2024' />
        <br />
        <ColabButton notebookUrl="asd" paperURL="" />

        <SubTitle Title="Introduction" noMarginTop={true} />
        <br />
        <Paragraph text="In this article, I will demonstrate how to process a highly unbalanced tabular dataset related to fraudulent electricity 
        and gas consumption. The goal is to utilize a combination of decision tree models to identify and detect potential cases of fraud. All 
        resources and references used in this project are listed at the bottom of the page, and the full article can be accessed through a public 
        Google Colaboratory notebook." />
        <br />
        <Paragraph text="The dataset used in this project, titled 'Fraud Detection in Electricity and Gas Consumption', originates from the Tunisian Company of Electricity and Gas (STEG), a public, non-administrative 
        company responsible for delivering electricity and gas across Tunisia. Due to fraudulent manipulations of meters by consumers, STEG suffered significant 
        financial losses, amounting to approximately 200 million Tunisian Dinars. This dataset represents consumer billing history, and the presence of fraud in 
        its records highlights the critical need for an effective detection system." />
        <br />
        <Paragraph text="This project aims to apply a range of data analytics and processing techniques, and to compare their effectiveness in identifying 
        patterns and anomalies within the dataset. By evaluating different methods, we seek to determine the most reliable approach for uncovering valuable 
        insights in this context." />
        <br />

        <SubTitle Title="Project Methodology" />
        <br />
        <Paragraph text="The methodology in this project will begin with..." />
        <br />

        <SubTitle Title="Importing the necessary libraries" />

        <br />

        <SubTitle Title="Downloading the dataset" />
        <br />
        <Paragraph text="The dataset for this project is available on Kaggle although it was just like meantioned before first available ....... To download it directly using code, you will need a Kaggle account and 
        an API key, both of which are free. A reference to the dataset can be found in the references section of this article." />
        <br />
        <CodeBlock code='opendatasets.download("https://www.kaggle.com/datasets/mrmorj/fraud-detection-in-electricity-and-gas-consumption")' />
        <br />

        


        <SubTitle Title="Exploratory Data Analysis" />
        <br />
        <Paragraph text="The dataset appears to consist of five CSV files: `invoice_train.csv`, `invoice_test.csv`, `client_train.csv`, `client_test.csv`, and 
        `SampleSubmission (2).csv`. Since this dataset was originally released for a competition on the Zindi platform, an example submission file was also 
        included to help competitors understand the submission format. As I do not plan to submit predictions, I will ignore that file. Additionally, 
        I will not use the client and invoice test files, as I will conduct all testing using a subset of the training sets." />
        <br />
        <CodeBlock code={import_csv} />
        <br />
        <Paragraph text="Using the `.info()` function, it is clear that the client data is organized into six columns: `disrict`, `client_id`, `client_catg`, 
        `region`, `creation_date`, and `target`. The `client_id` seems to be a unique identifier for each client, while the `disrict` and `region` columns provide information about the client’s location, with `disrict` representing a more specific area and `region` indicating a broader geographical area. The `client_catg` column classifies clients into different categories, providing insight into the type of clients. The `creation_date` shows when the client joined or was registered. Finally, the `target` column is a binary indicator, where 1 signifies a fraudulent client and 0 indicates a non-fraudulent one." />
        <br />
        <CodeBlock code='df_client.info()' />
        <OutputText text={output_example} />
        <br />

        <Paragraph text="The invoice data seems to be organized in 16 columns, starting with `client_id`, a unique identifier for each client, and `invoice_date`, which records the date of the invoice. The `tarif_type` specifies the type of tax applied. `counter_number` tracks the identifier for the counter, while `counter_statue` reflects the status of the counter, which can take values such as working fine, not working, or on hold. The `counter_code` 
        records a specific code related to the counter. `reading_remarque` contains notes made by the STEG agent during their visit, such as a negative score if there is an issue with the counter. `counter_coefficient` is an additional coefficient applied when standard consumption levels are exceeded. The consumption data is divided into four levels: `consommation_level_1`, `consommation_level_2`, `consommation_level_3`, and `consommation_level_4`. 
        `old_index` and `new_index` represent the previous and current counter readings, respectively, while `months_number` tracks the number of months covered by the invoice. Finally, the `counter_type` indicates the type of counter used." />
        <br />
        <CodeBlock code='df_invoice.info()' />
        <OutputText text={example_2} />
        <br />
        <CodeBlock code='df_client.head(5)' />
        <OutputText text={another_example} />
        <br />
        <CodeBlock code='df_invoice.head(5)' />
        <OutputText text={another_example_2} />
        <br />

        <Paragraph text="The `.describe()` function provides a summary of statistical information for the numerical columns in a pandas dataframe. From 
        this summary, it is possible to observe that the dataset is highly imbalanced, as indicated by the mean value of the `target` column being 0.0558. This suggests that only about 5.58% of the entries are associated with fraud. The other three columns ( `disrict`, `client_catg`, and `region`) are also numeric columns, but they actually represent categorical data. Therefore, their summary statistics do not provide much meaningful insight beyond showing their range and distribution." />
        <br />
        <CodeBlock code='df_client.describe()' />
        <OutputText text={describe_1} />
        <br />
        
        <Paragraph text="By analyzing the unique values in the categorical columns, we can gain a better understanding of the dataset's structure. It appears that the `client_catg` column has 3 distinct client categories, the `disrict` column has 4 different district categories, and the `region` column contains 25 unique region categories." />
        <br />
        <CodeBlock code='df_client.client_catg.unique()' />
        <OutputText text='**array([**11, 12, 51**])**' />
        <br />
        <CodeBlock code='df_client.disrict.unique()' />
        <OutputText text='**array([**60, 69, 62, 63**])**' />
        <br />
        <CodeBlock code='df_client.region.unique()' />
        <OutputText text={array} />
        <br />

        <CodeBlock code='df_client.creation_date' />
        <OutputText text={date_table} />
        <br />
        <CodeBlock code='pandas.to_datetime(df_client.creation_date).describe()' />
        <OutputText text={sorted_array} />
        <br />

        {/*
        <Paragraph text="A clearer understanding of the dataset's organization can be achieved by plotting the number of reports and fraud cases over the years. The plot shows that in 2019, there was a sharp decrease in reports." />
        <br />
        <CodeBlock code={plot_values} />
        <br />
        <CodeBlock code="plot_transactions_and_fraud(df_client)" />

        <div className='flex content-center pt-2 pl-2'>
            <img className='w-[680px]' src={process.env.PUBLIC_URL + "/Detecting-Fraud-in-Electricity-and-Gas-Consumption-Using-Decision-Tree-Models-I/plot.jpeg"} />
        </div>

        <br />
        */}

        <Paragraph text="Although the dataset is known to be highly imbalanced in terms of fraud versus non-fraud cases, the exact extent of imbalance within each category of the client dataframe is still unclear." />
        <br />
        <Paragraph text="The following function analyzes this imbalance by calculating the percentage of fraud reports within each category of a specified feature and determining how much each category contributes to the total fraud cases. It compares the proportion of fraud within each category to the overall number of reports, highlighting imbalances and offering insights into the distribution of fraud across different categories. " />
        <br />
        <CodeBlock code={balance} />
        <br />
        <CodeBlock code="fraud_percentage('disrict')" />
        <OutputText text={f1} />
        <br />
        <CodeBlock code="fraud_percentage('region')" />
        <OutputText text={f2} />
        <br />
        <CodeBlock code="fraud_percentage('client_catg')" />
        <OutputText text={f3} />
        <br />
        <Paragraph text="As seen in the previous outputs, it is clear that the categories are very unbalanced. For example, in the 'district' feature, the fraud percentages within each category vary significantly, with district 69 showing a fraud rate of 7.15%, while district 60 has a much lower rate at 3.59%. In the 'region' feature, the fraud percentage ranges from as high as 10.30% in region 103 to as low as 0.57% in region 399, with most regions falling between 3% and 8%. The 'client_catg' feature is similarly imbalanced, with category 51 having a notably high fraud percentage of 16.87%, but it only accounts for 3.74% of total fraud cases, whereas category 11 dominates with 95.04% of total fraud despite having a lower fraud percentage of 5.47%. These disparities highlight significant differences in fraud distribution across various categories." />
        <br />
        <Paragraph text="Even if it’s known how unbalanced each category is, the exact correlation between features is still unclear, which is essential for selecting the best features for the model. Calculating the correlation between variables, whether categorical or continuous, can be done using different methods depending on the nature of the data. For categorical-categorical relationships, Cramér's V can be used to measure the strength of association. For binary and continuous variables, the point-biserial correlation is appropriate, while the Pearson correlation measures the linear relationship between two continuous variables. These correlation methods help in identifying the most relevant features for building a more accurate model. I won’t go into depth about the mathematical formulations here, but each method is tailored to handle different types of data effectively." />
        <br />
        <CodeBlock code={cram} />
        <br />
        <CodeBlock code={bis} />
        <br />
        <CodeBlock code={pearson} />
        <br />
        <Paragraph text="The following function uses the previously created three functions to accurately determine the correlations between the features in the client dataframe. Depending on the data type, it applies the appropriate function and outputs a dataframe with all the correlations." />
        <br />
        <CodeBlock code={corrs} />
        <br />
        <CodeBlock code={p} />
        <OutputText text={corrs_o} />
        <br />
        <Paragraph text="The correlation matrix indicates that the target variable (fraud) has very weak correlations with most features. Specifically, the correlations between target and other variables like client_catg and creation_date are minimal, with values around 0.05. This suggests that these features have little to no direct predictive power regarding fraud. Even the correlation between target and district is only 0.058241, and with region, it's slightly higher at 0.084384, yet still weak. The high correlation between district and region (0.980419) further emphasizes that while these features might be redundant, they don't significantly impact the target variable. Overall, the low correlations imply that more advanced feature engineering or additional methods may be required to effectively identify predictors of fraud." />
        <br />
        <Paragraph text="In summary, the dataset appears to be highly unbalanced, and the client dataframe does not contain sufficient information to accurately predict the target label. Additionally, most features in the client dataframe show negligible correlation with the target, suggesting they may be irrelevant for prediction purposes. Next, the focus will shift to analyzing the invoice dataset. The balance of features will be checked similarly, followed by an assessment of their correlation with the target." />
        <br />
        <Paragraph text="The code below adds the target column from the client dataframe to the invoice dataframe, allowing for the accurate analysis of the feature balance and correlation in the invoice dataframe." />
        <br />
        <CodeBlock code='df_invoice.head(5)' />
        <OutputText text={another_example_2} />
        <br />
        <CodeBlock code="df_invoice = df_invoice.merge(df_client[['client_id', 'target']], on='client_id', how='left')
df_invoice.head(5)" />
        <OutputText text={output_test_2} />
        <br />
        <Paragraph text="The dataset shows considerable variability, especially in fields like counter_number and the consumption indices, where extreme values and wide ranges suggest potential outliers or data entry issues. Most consumption fields, particularly levels 2, 3, and 4, are sparse, with many zero values, indicating these categories may not be used often. Typical billing cycles seem to be around four months, but there are outliers with unusually high values. Overall, the data presents high variability and potential for anomalies, particularly in consumption and time-related columns." />
        <br />
        <CodeBlock code="df_invoice.describe()" />
        <OutputText text={describe_4} />
        <br />
        <Paragraph text="Since this dataframe has a lot more columns than the previous client dataframe, i wrote this code that prints the length for each columns unique values
        instead of doing it manually. From the output we can derive that there are 6 categorical columns, without counting the target column, which are the tarif_type, counter_statue,
        counter_code, reading_remarque, counter_coefficient and counter_type." />
        <br />
        <CodeBlock code={uniques} />
        <OutputText text={uniques_o} />
        <br />
        <Paragraph text="By examining the distribution of fraud across different tarif_type categories, most fraud cases come from tarif types 11 and 40, contributing 55.9% and 32.1% of total fraud, respectively. Although tarif type 45 has fewer fraud cases overall, it exhibits the highest fraud rate within its category, with 19.04%. An extreme case of imbalance is seen in tarif type 18, where all 4 reports are fraud, resulting in a 100% fraud rate. This suggests that certain tarif types may be more prone to fraud, potentially indicating gaps in monitoring or policy enforcement." />
        <br />
        <CodeBlock code="fraud_percentage('tarif_type', df=df_invoice, target='target')" />
        <OutputText text={b1} />
        <br />
        <Paragraph text="In the counter_statue category, category 0 dominates the dataset, comprising 97.8% of total reports with a fraud rate of 7.88%. Categories 1 and 5 show notable fraud rates, with 7.29% and 10.46%, respectively. These categories contribute only small fractions to the total fraud, but the higher fraud rates suggest specific vulnerabilities. Categories with very few reports (e.g., 46, A, 618) show no fraud, though these categories are too sparse for meaningful analysis. The high concentration of fraud cases in just a few categories could indicate specific operational inefficiencies that should be addressed." />
        <br />
        <CodeBlock code="fraud_percentage('counter_statue', df=df_invoice, target='target')" />
        <OutputText text={b2} />
        <br />
        <Paragraph text="In the counter_code category, fraud cases are concentrated in a few key codes. Code 203 contributes the most fraud, with 7.49% fraud within its category and making up 32.19% of total fraud cases. Codes 5 and 207 follow, with fraud rates of 8.12% and 5.78%, respectively, contributing significantly to the overall fraud total. The highest fraud percentage is seen in code 10, with 12.96% fraud cases, despite a lower total report count, suggesting that smaller groups may exhibit higher fraud rates. Lower-frequency codes (like 310, 307, and others) show no fraud, but their report counts are too small to draw strong conclusions. The data suggest that monitoring efforts should focus on larger groups while keeping an eye on smaller codes with disproportionately high fraud rates." />
        <br />
        <CodeBlock code="fraud_percentage('counter_code', df=df_invoice, target='target')" />
        <OutputText text={b3} />
        <br />
        <Paragraph text="For reading_remarque, categories 6, 9, and 8 account for almost all fraud cases, with fraud rates of around 7.7% to 8.18%. Remark 6 has the most reports, contributing 48.7% of total fraud. Remark 9 follows closely, contributing 32.1% of fraud. Remark 7 shows only 1 fraud case out of 661 reports, indicating that this category may be of low risk. The other categories (203, 413, 207, etc.) report no fraud cases, likely due to the very low number of total reports. This distribution points to the need for focused attention on the categories with larger volumes of reports, especially those with high fraud percentages." />
        <br />
        <CodeBlock code="fraud_percentage('reading_remarque', df=df_invoice, target='target')" />
        <OutputText text={b4} />
        <br />
        <Paragraph text="In the counter_coefficient category, coefficient 1 overwhelmingly dominates the data, accounting for 99.99% of total fraud with a fraud rate of 7.89%. All other coefficients have either zero fraud cases or extremely low report counts. Coefficient 5, despite having only 1 total report, shows a 100% fraud rate, and coefficient 10 has a 16.67% fraud rate, though both categories are statistically insignificant due to their small sample sizes. This extreme imbalance suggests that efforts should focus on coefficient 1, as it holds the vast majority of reports and fraud cases. Smaller coefficients may not warrant the same attention given their minimal impact." />
        <br />
        <CodeBlock code="fraud_percentage('counter_coefficient', df=df_invoice, target='target')" />
        <OutputText text={b5} />
        <br />
        <Paragraph text="The counter_type category shows that electricity (ELEC) accounts for 66.93% of total fraud cases, with a fraud rate of 7.68%. Gas (GAZ) has a slightly higher fraud rate of 8.36%, but contributes 33.07% of the total fraud cases due to fewer overall reports. Both counter types show similar trends in fraud percentages, but the higher volume in electricity reports makes it the primary focus area for fraud prevention. Gas counters, despite fewer total reports, should also be closely monitored due to their higher fraud rate." />
        <br />
        <CodeBlock code="fraud_percentage('counter_type', df=df_invoice, target='target')" />
        <OutputText text={b6} />
        <br />
        <Paragraph text="do the corr" />
        <br />
        <CodeBlock code={pp} />
        <OutputText text={ppp} />



        <DownSpace />
    </div>
  )
}

export default YOLO